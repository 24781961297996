import React from 'react';
import Header from '../../components/Header';
import Ab from '../../components/Ab';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import api from '../../Services/api';

const Div = styled.div`
    background-color: rgb(250, 250, 250);
    padding: 20px;
`;

const Inst = styled.div`
    margin-top: 70px;
    #l2{
        border-bottom: 2px solid #B54949;
    }
    .h1hhh{
        width: 100%;
        height: 100px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-top: 70px;
    }
    .h1hh{
        margin-left: 6%;
        padding-top: 25px;
        font-size: 30px;
    }
    @media (max-width: 580px){
        .h1hhh{
            width: 100%;
            height: 75px;
            color: rgba(0, 0, 0, 0.46);
            background-color: #c4c4c4;
        }
        .h1hh{
            margin-left: 10%;
            padding-top: 20px;
            font-size: 25px;
        }
    } 
`;
const dados = {
    historia:[
        {
            titulo: "Historial",
            descricao: `<p>Arquivo Nacional de Angola, foi criado ao abrigo da portaria nº 6, de 8 de Setembro de 1938, 
            (B.O1938,1ªSérie), aquando da criação do Museu de Angola, incorporando nas suas Instalações, 
            sitas no extinto depósito de degredados (Fortaleza de São Miguel), a Biblioteca e o Arquivo Histórico, 
            este último dependente do Arquivo Histórico Colonial sediado em Lisboa.</p>
            <p class="p">.</p>
            <p>Desde a sua fundação, os seus fundos estiveram sujeitos a várias movimentações. Iniciada a sua instalação 
            nos serviços de Instrução, passando pela fortaleza de São Miguel, em 1958, dizia-se que a título provisório, 
            iria para a cave do edifício do então Museu de Angola, hoje Museu Nacional de História Natural.
            Todas as mudanças, naturalmente, terão causado perdas consideráveis. Em 1977, regista-se a transferência 
            do Arquivo para o edifício em que permanece hoje, sito na Rua Pedro Félix Machado nº 49 R/C, em Luanda.</p>`
        },
        {
            titulo: "Informação Existente no ANA",
            descricao: ` Os fundos do ANA são constituídos por toda a documentação produzida pela administração Colonial 
            no território que hoje é Angola: 12.000 códices manuscritos dos Sec. XVIII-XIX
            5000 caixas de documentos dos sec. XIX-XX.Milhares de imagens (encontram-se do Sector dos Visuais) e 
            estão distribuídos pelas seguintes secções: Pinacoteca (aguarelas e desenhos sobre papel); 
            Fototeca (fotografias maioritariamente etnografia e imagens de grandes eventos); Cartoteca (mapas) e 
            Microfilmes.Na Biblioteca Histórica encontramos 25.000 títulos na sua maioria sobre 
            temas da Ciências Sociais, para apoio a pesquisa. A vida da Documentação no ANA`
        },
        {
            titulo: "Período colonial",
            descricao: ``
        },
        {
            titulo: "Período Pós independência",
            descricao: ``
        }
    ],
    organograma:{
        img: 'Organograma.png'
    },
    missao:{
        descricao: `<p>O Arquivo Nacional de Angola, abreviadamente (ANA) é um instituto público, de vocação científica, 
        cuja natureza cultural e de investigação científica no domínio da arquivística visa a preservação 
        da memória nacional e de promoção dos estudos na área das ciências sociais.
        </p>
        <p class="p">.</p>
        <p>
        O Arquivo Nacional de Angola tem como objecto principal coordenar a política arquivística nacional 
        e supervisionar o funcionamento do sistema nacional de arquivos, sendo o órgão reitor da política 
        arquivística nacional.
        </p> 
        <p class="p">.</p>
        <p>Arquivo Nacional de Angola, foi criado ao abrigo da portaria nº 6, de 8 de Setembro 
        de 1938, (B.O1938,1ªSérie), aquando da criação do Museu de Angola, incorporando nas suas Instalações, 
        sitas no extinto depósito de degredados (então Fortaleza de São Miguel), a Biblioteca e o Arquivo Histórico, 
        este último dependente do Arquivo Histórico Colonial sediado em Lisboa.
        </p>
        <p class="p">.</p>
        <p>Desde a sua fundação, os seus 
        fundos estiveram sujeitos a várias movimentações. Iniciada a sua instalação nos serviços de Instrução, 
        passando pela fortaleza de São Miguel, em 1958, dizia-se que a título provisório, iria para a cave do 
        edifício do então Museu de Angola, hoje Museu Nacional de História Natural.
        </p>
        <p class="p">.</p>
        <p>Todas as mudanças, naturalmente, terão causado perdas consideráveis. Em 1977, regista-se a transferência do 
        Arquivo Nacional para o edifício em que permanece até hoje, sito na Rua Pedro Félix Machado nº 49 R/C, 
        em Luanda.
        </p>
        <p class="p">.</p>
        <p>O Arquivo Nacional de Angola, tem como função principal coordenar a política 
        arquivística nacional, competindo-lhe recolher, classificar, conservar, divulgar e supervisionar a 
        avaliação dos documentos de valor aquivístico e histórico.</p> 
        <p class="p">.</p>
        <p>Cabe também, ao Arquivo Nacional de Angola, 
        formular as directrizes gerais e supervisionar metodologicamente o funcionamento do Sistema 
        Nacional de Arquivos, promover acções de formação e valorização dos recursos humanos no dóminio da 
        arquivística, promover a investigação no domínio da história de Angola, bem como auxiliar no 
        desenvolvimento das demais ciências sociais. 
        </p>
        <p class="p">.</p>
        <p>O Arquivo Nacional de Angola, concentra no seu depósito 
        central todos os fundos provenientes da administração colonial e que reúne os requisitos exigidos, 
        e a quem tem vindo a incorporar de alguns fundos classificados dos orgãos centrais de administração 
        do estado angolano.
        </p>`
    },
    legislacao:{
        descricao: ``
    },
    contactos:{
        descricao: `O Arquivo Nacional de Angola, concentra no seu depósito 
        central todos os fundos provenientes da administração colonial e que reúne os requisitos exigidos, 
        e a quem tem vindo a incorporar de alguns fundos classificados dos orgãos centrais de administração 
        do estado angolano.`
    }
}
const Tudo = styled.div`
    #l2{
        border-bottom: 2px solid #B54949;
    }
`;

export default class Institucional extends React.Component{
    
    state = {
        ab: [],
    }
    componentDidMount(){
        this.abLoad();
    }

    abLoad = async () =>{
        const res = await api.get('/institucional');
        
        this.setState({ab: res.data});
        console.log(this.state.ab)
    }


    render(){
        window.scrollTo(0, 0);
        return (
            <Tudo>
                <Header />
                <Inst>
                    <div class="h1hhh">
                        <p class="h1hh">Início/ Institucional</p>
                    </div>
                    <Div>
                        <Ab dados={this.state.ab}/>
                    </Div>
                </Inst>
                <Footer />
            </Tudo>
        )
    }
}