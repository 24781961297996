import React, { Component } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const Div = styled.div`
    .div{
        margin: 30px 80px;
    }
    .h1{
        color: #1E266D;
    }
    .h5{
        margin: 0px -20px;
    }
    iframe{
        height: 60vh;
        width: 100%; 
        frameBorder: 0; 
        border: 0; 
        allowfullscreen: ; 
        aria-hidden: false; 
        tabindex: 0;
    }
    @media (max-width: 425px){
        .div{
            margin: 20px 40px;
        }
    }
`

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Mapa extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  render() {
    return (
        <Div>
            <div className="div">
              <h1 className="h1">Localização</h1>
              <h5 className="h5">
                  <IconButton>
                    <LocationOnIcon />
                  </IconButton>Rua Pedro Félix Machado 49, Luanda
              </h5>
            </div>
            <div style={{ height: '60vh', width: '100%' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3371.4938495322654!2d13.2555068!3d-8.9622906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a521f6da89f992b%3A0x3662c7a7ef3a9023!2sArquivo%20Nacional%20de%20Angola-NOVA%20INSTALA%C3%87%C3%83O!5e1!3m2!1spt-PT!2sao!4v1606291793852!5m2!1spt-PT!2sao"></iframe>
            </div>
      </Div>
    );
  }
}

export default Mapa;