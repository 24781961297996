import React from 'react';
import Header from '../../components/Header';
import SlideShow from '../../components/Slide';
import Statistic from '../../components/Statistic';
import Space from '../../components/Space';
import Footer from '../../components/Footer';
import Services from '../../components/PanelService';
import GalleryComponents from "../../components/GalleryComponents/Gallery";
import styled from 'styled-components';
import api from '../../Services/api';

const Div = styled.div`
    margin-top: 75px;
`;

const Tudo = styled.div`
    #l1{
        border-bottom: 2px solid #B54949;
    }
`;

export default class Home extends React.Component{

    state = {
        hero: [],
        gallery: [],
        statistic: [],
        space: [],
      }
      componentDidMount(){
        
        this.heroLoad();
        this.galleryLoad();
        this.spaceLoad();
        
      }
    
      heroLoad = async () =>{
        const res = await api.get('/banner');
        
        this.setState({hero: res.data});
      }
      galleryLoad = async () =>{
        const res = await api.get('/galeria');
        
        this.setState({gallery: res.data});
      }
      spaceLoad = async () =>{
        const res = await api.get('/destaque');
        
        this.setState({space: res.data});
      }

    render(){
        window.scrollTo(0, 0);
        return (
            <Tudo>
                <Header />
                <Div>
                    <SlideShow dados={this.state.hero} />
                    <GalleryComponents dados={ this.state.gallery} />
                    <Statistic />
                    <Space dados={this.state.space} />
                    <Footer />
                </Div>
            </Tudo>
        )
    }
}