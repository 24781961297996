import React from 'react';
import styled from 'styled-components';

import { 
  Typography,
  IconButton,
} from '@material-ui/core';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import { Left } from 'react-bootstrap/lib/Media';

const Div = styled.div`
  width: 80%;
  a{
    backgroun-color: white;
  }
  #l4{
    background-color: #095EB2;
    color: white;
  }
  .info{
    margin-top: 40px;
  }
  .bodyTxt{
    border-top: 1px solid #B54949;
    margin-top: 10px;
    margin-left: -10%;
    height: 500px;
    background-color: #F5F5F5;
  }
  @media (max-width: 1024px){
    .info{
      margin-left: 2%;
      margin-top: 40px;
      font-size: 2px;
    }
    margin-bottom: -50px;
  }
  @media (max-width: 768px){
    width: 70%;
    .info{
      margin-left: 1%;
      margin-top: 40px;
    }
    .info{
      width: 253px;
      margin-left: 80px;
    }
    margin-bottom: -50px;
  }
  @media (max-width:425px){
    .bodyTxt{
      border-top: 1px solid #B54949;
      margin: 550px 0px 0px -350px;
      width: 350px;
      height: 450px;
    }
    .info{
      width: 90%;
      margin-left: 10%;
      margin-top: 20px;
    }
    margin-bottom: 30px;
  }
`;
const Title = styled.div`
  border-bottom: 1px solid rgb(240, 240, 240);
  padding: 30px 30px 30px 0px;
  font-size: 40px;
  width: 86%;
  margin-left: 7%;
`;

function Contacto() {
  return (
    <>
    <div style={{margin: '-20px', position:'absolute',  right:'400px'}}><b>Endereço</b></div>
    <Div className="Contacto">
      <div className='bodyTxt'>
        <div class="info">
      <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <LocationOnIcon />
          </IconButton>
          Aberto de Segunda a Sexta das 08H30 às 15H00
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <LocationOnIcon />
          </IconButton>
          Rua Pedro Félix Machado 49, Luanda
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <EmailIcon />
          </IconButton>
          Email : arquivonacionaldeangolca@gmail.com
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <EmailIcon />
          </IconButton>
          Email : secretariadoana@hotmail.com
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <PhoneIcon />
          </IconButton>
          Tel: (+244) 990000000
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontWeight: "bold"}}> 
          <IconButton>
              <PhoneIcon />
          </IconButton>
          Tel: (+244) 900900009
        </Typography>
        </div>
      </div>
    </Div>
    </>
  );
}

export default Contacto;
