import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import styled from 'styled-components';
import Burger from './Burger';

import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-7),
      width: 'auto',
    },
  },
  inputRoot: {
    color: '#1E266D',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    border: '1px solid #1E266D',
    borderRadius: '15px',
    [theme.breakpoints.up('md')]: {
      width: '15ch',
    },
  },
}));

const Ul = styled.div`
        z-index: 10;
        top: 0;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space;
        width: 100%;
        background-color: white;

        .logo{
            margin-left: 5%;
        }

        .btnS{
            position: absolute;
            z-index: 1;
            top: 2px;
            left: 2px;
            padding: 5px !important;
        }

        ul{
            margin-left: 19.5%;
            @media (max-width: 980px) {
                display:none;
            }
            
            display: flex;
            flex-direction: row;
            margin-top: 13px;

            li{
                padding: 8px 16px;
                list-style: none;
            }
            a{
                text-decoration: none;
                color: #1E266D;
            }
            #l6{
                padding: 9px 17px;
                background-color: #B54949;
                border-radius: 100px;
                text-align: center;
                color: white;
            }
        }
        @media (max-width: 1024px) {
            ul{
                margin-left: 11%;
            }
            .inputRoot{
                width: 10px;
            }

        }
`;

const RightNav = (props) => {
    const classes = useStyles();
    const [search, setSearch] = useState("!");

    return(

        <Ul bg={props.bg}>
            <div className="logo">
                <Link to='/'><img src={logo} /></Link>
            </div>
            <ul>
                <Link to='/' id="l1"><li >Início</li></Link>
                <Link to='/Institucional' id='l2'><li>Institucional</li></Link>
                <Link to='/Servicos' id='l3'><li>Serviços</li></Link>
                <Link to='/Catalogo' id='l7'><li>Catálogo</li></Link>
                <Link to='/Notices' id='l4'><li>Notícias</li></Link>
                <Link to='/Contactos' id='l5'><li>Contactos</li></Link>
            </ul>
            <ul>
                <div className={classes.search}>
                        <form>
                           <Link 
                            class="link" 
                            to={
                                    {
                                        pathname:'/Pesquisar',
                                        state:{
                                            search: search,
                                            value: true,
                                        }
                                    }
                                }
                            >
                                <button>
                                <IconButton 
                                    className="btnS" 
                                    aria-label="search" 
                                    color="inherit"
                                >
                                    <SearchIcon />
                                </IconButton>
                                </button>
                            </Link>
                        
                    
                        <InputBase
                        placeholder="Pesquisar…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                    </form>
                </div>
            </ul>
            <Burger pageWrapId={"page-wrap"} outerContainerId={"App"}/>
        </Ul>
    )
}

export default RightNav;