import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from "./pages/Home";
import Institucional from "./pages/Institucional";
import Servicos from "./pages/Servicos";
import Catalogo from "./pages/Catalogo";
import Notices from "./pages/Notices";
import Contactos from "./pages/Contactos";
import Espaco from "./pages/Espaco";
import Pesquisar from "./pages/Pesquisar";
import Acervo from "./pages/Acervo";
import Apoio from "./pages/Apoio";
import Leitura from "./pages/Leitura";
import Laboratorio from "./pages/Laboratorio";
const Routes = () =>(
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/Institucional" component={Institucional} />
            <Route path="/Servicos" component={Servicos} />
            <Route path="/Catalogo" component={Catalogo} />
            <Route path="/Notices" component={Notices} />
            <Route path="/Contactos" component={Contactos} />
            <Route path="/Espaco" component={Espaco}/>
            <Route path="/Pesquisar" component={Pesquisar}/>
            <Route path="/Acervo" component={Acervo}/>
            <Route path="/Apoio" component={Apoio}/>
            <Route path="/Leitura" component={Leitura}/>
            <Route path="/Laboratorio" component={Laboratorio}/>
        </Switch>
    </BrowserRouter>
)

export default Routes;