import React from 'react';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Location from '../../components/Location';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import Mapa from '../../components/Mapa';

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(250, 250, 250);
    padding: 50px;
`;

const Tudo = styled.div`
    #l3{
        border-bottom: 2px solid #B54949;
    }
    .h1hhhh{
        width: 100%;
        height: 100px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-top: 70px;
    }
    .h11{
        margin-left: 6%;
        padding-top: 25px;
        font-size: 30px;
    }
    
    .jrNSFD .p {
        text-align: justify;
        border-top: 2px solid #B54949;
        padding: 30px 0px;
        margin-top: 30px;
        color: rgba(0,0,0,0.70);
    }
    .jrNSFD .cont {
        width: 50%;
        text-align: center;
        margin: 70px 0px 70px 25%;
    }
    .p{
        width:60%;
        text-align: justify;
    }
    figure {
        float:right;
        margin:0 10px 10px 0;
    }
    .container-auto{
        position:static;
        max-width:1000px;
        padding:0px 30px;
        margin:0 auto;
        width:100%;
    }
    header{ 
        border-botton: solid 3px #c12524 !important;
    }
`;



export default function Acervo(){
    window.scrollTo(0, 0);
    return (
        <Tudo>
            <Header />
                <div class="h1hhhh">
                    <p class="h11">Consulta de Acervo</p>
                </div>
               
                
                
            <Div>
                <div class='container content container-auto'>
                <div>
                    <figure>
                        <img  style={{width: '50%',}} src={require('../../img/img_6.jpg')} alt='imagem Xis'/>
                    </figure>
                </div>
                <hr class='space m'/>
           <div class='p'>
           <p >What is Lorem Ipsum?</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Why do we use it?
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            </div>
            </div>
            </Div>
           
            <Footer />
        </Tudo>
    )
}