import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Ler from '../../components/Ler';
import styled from 'styled-components';

const Div = styled.div`
margin-top: 70px;
`;

export default function Espaco(){
    window.scrollTo(0, 0);
    return (
        <>
            <Header/>
            <Div>
                <Ler />
                <Footer />
            </Div>
        </>
    )
}