import React from 'react';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Location from '../../components/Location';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import Mapa from '../../components/Mapa';

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(250, 250, 250);
    padding: 50px;
`;

const Tudo = styled.div`
    #l3{
        border-bottom: 2px solid #B54949;
    }
    .h1hhhh{
        width: 100%;
        height: 100px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-top: 70px;
    }
    .h11{
        margin-left: 6%;
        padding-top: 25px;
        font-size: 30px;
    }
    
    .jrNSFD .p {
        text-align: justify;
        border-top: 2px solid #B54949;
        padding: 30px 0px;
        margin-top: 30px;
        color: rgba(0,0,0,0.70);
    }
    .jrNSFD .cont {
        width: 50%;
        text-align: center;
        margin: 70px 0px 70px 25%;
    }
`;



export default function Apoio(){
    window.scrollTo(0, 0);
    return (
        <Tudo>
            <Header />
                <div class="h1hhhh">
                    <p class="h11">Apoio de Consulta</p>
                </div>
               
                
                
            <Div>
            <div class="cont" style={{textAlign:'center'   }}><h2>Apoio de Consulta</h2>
            <p class="p">
            <p style={{textAlign:'justify', borderTop:'2px solid #B54949',padding:'20px 0px', margin:'70px 0px 70px 25%', marginTop:'30px', color:'rgba(0,0,0,0.70)', width:'50%' }}>Domínio legislativo: divulgação da Lei Geral de Arquivos sentido de permitir a todos os organismos públicos e privados uma actuação...</p></p></div>
           
            </Div>
           
            <Footer />
        </Tudo>
    )
}