import React from 'react'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import styled from 'styled-components';

const Div = styled.div`

    width: 90%;
    margin-left: 6%;
    .Tudo{
        .root{
            display: flex;
            flexWrap: wrap;
            justifyContent: space-around;
            overflow: hidden;
            backgroundColor: theme.palette.background.paper;
        }
        Tudo{
            width: 90%;
        }
    }
    .h1{
        margin-top: 70px;
        text-align: center;
    }
`

class GridImages extends React.Component{

    constructor(props){
        super(props);
    } 
     
    render(){
        return(
            <Div>
                <div class="h1">
                    <h1 style={{margin: '30px', color: '#1E266D'}}>Explore todas as memórias de Angola</h1>
                </div>
                <div className="root">
                    <GridList cellHeight={130} className="Tudo" cols={7}>
                        {this.props.dados.map((tile,key) => (
                            <GridListTile key={key} cols={tile.cols || 1}>
                            {tile.img && (
                                <div className="container-imageBottom">
                                <p id="description-image" onClick={ function(){  
                                    document.getElementsByClassName("parentContainer")[0].style.display = 'flex';
                                    console.log("Ola Mundo");
                                    document.getElementsByClassName("imgContainer")[0].src =  `https://ana.gov.ao/backend/u/${tile.img}`;
                                        
                                }}>Texto qualquer</p>
                                <img src={`https://ana.gov.ao/backend/u/${tile.img}`} className="imgFlex" />
                                
                            </div>
                            )}
                            
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </Div>
        )
    }
}
export default GridImages;