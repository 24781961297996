import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

const Div = styled.div`

    width: 100%;

    .p{
      color: white;
    }
    .geral{
      margin-left: 20px;
    }
    
    @media (max-width: 425px){
      .bodyTxt{
        width: 116%;
        margin-left: -8%;
        margin-top: -3.5vh;
      }
    }
    @media (max-width: 375px){
      .bodyTxt{
        width: 117%;
        margin-left: -8.5%;
        margin-top: -3.5vh;
      }
    }
    @media (max-width: 320px){
      .bodyTxt{
        width: 121%;
        margin-left: -10.5%;
        margin-top: -3.5vh;
      }
    }
  
`;

const Line = styled.div`
  width: 100%;
  margin-left: 50%;
  
  @media (max-width: 1024px){
    margin-left: 0%;
  }
`;
const Line1 = styled.div`
  width: 100%;
  margin-left: 35%;
  
  @media (max-width: 1024px){
    margin-left: 0%;
  }
`;

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Panel(props) {
  const [expanded, setExpanded] = React.useState(`panel${props.exp}`);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <>
      <Div>
      <div className='bodyTxt'>
        <div>
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{props.dados[0].titulo}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" gutterBottom>
              <div class="geral">
              <div class="info">
                <p class="p">.</p>
                  {props.dados[0].descricao}
                <p class="p">.</p>
                <p class="p">.</p>
              </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>{props.dados[1].titulo}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" gutterBottom>
            <div class="geral">
                <div class="info">
                <p class="p">.</p>
                {props.dados[1].descricao}
                <p class="p">.</p>
                <p class="p">.</p>
                </div>
                </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Período Colonial</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <Line>
                <Timeline lineColor={'#ddd'}>
                    <TimelineItem
                        key="001"
                        dateText="1833"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>1ª Inventariação de documentos da “província de Angola”</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="002"
                        dateText="1891"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>Recolha da documentação anterior a 1834 no arquivo da
                        torre do Tombo</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="003"
                        dateText="1933"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>Publicaçaõ dos “arquivos de Angola”</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="004"
                        dateText="1938"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>Portaria que cria o Museu de Angola, a Biblioteca e o
                        Arquivo</h3>
                    </TimelineItem>
                    
                    <TimelineItem
                        key="005"
                        dateText="1939"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>Criaçao do Arquivo Histórico de Angola</h3>   
                    </TimelineItem>
                    <TimelineItem
                        key="006"
                        dateText="1966"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>O Arquivo Histórico deixa de estar sob a alçada do Museu de Angola</h3>
                    </TimelineItem>
                    
                </Timeline>
              </Line>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Período pós Independência</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <Line1>
                <Timeline lineColor={'#ddd'} className="timeline">
                    <TimelineItem
                        key="001"
                        dateText="1976"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>Arquivo volta para o Museu de Angola</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="002"
                        dateText="1977"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>Transferencia do Arquivo para o prédio aonde esta até 
                          hoje, passa a chamar – se Centro Nacional de Documentação
                          e Investigação Histórica.</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="003"
                        dateText="1992"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>Passa a chamar-se Arquivo Histórico Nacional</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="004"
                        dateText="1999"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>Adstrito ao Ministério da Educação e Cultura</h3>
                    </TimelineItem>
                    
                    <TimelineItem
                        key="005"
                        dateText="2002"
                        style={{ color: '#B54949' }}
                        dateInnerStyle={{ background: '#B54949' }}
                    >
                        <h3>Volta a depender do Ministério da Cultura</h3>   
                    </TimelineItem>
                    <TimelineItem
                        key="006"
                        dateText="2009"
                        dateInnerStyle={{ background: '#ddd', color: 'black' }}
                    >
                        <h3>Aprovação do Estatuto Orgânico do ANA</h3>
                    </TimelineItem>
                    
                </Timeline>
              </Line1>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
    </Div>
    </>
  );
}

