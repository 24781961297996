import React, { useState } from 'react';
import styled from 'styled-components';
import {slide as Menu} from 'react-burger-menu';
import { Link } from 'react-router-dom';

import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0.8, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    color: 'white',
  },
  inputRoot: {
    color: 'white',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const StyledBurger = styled.div`

    display: none;
    margin-left: 55%;
    z-index: 10;

    @media (max-width: 980px) {
        display: block;
        margin-left: 58%;
    }
    @media (max-width: 768px) {
        display: block;
        margin-left: 75%;
    }
    @media (max-width: 425px) {
        display: block;
        margin-left: 65%;
    }
    @media (max-width: 375px) {
        display: block;
        margin-left: 55%;
    }
    @media (max-width: 320px) {
        display: block;
        margin-left: 56%;
    }
    .page-wrap{
        overflow: auto;
    }
        /* Individual item */
    .bm-item {
        display: inline-block;

        /* Our sidebar item styling */
        text-decoration: none;
        margin-bottom: 10px;
        color: #d1d1d1;
        transition: color 0.2s;
    }

    /* Change color on hover */
    .bm-item:hover {
        color: white;
    }

    /* The rest copied directly from react-burger-menu docs */

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: relative;
        width: 26px;
        height: 20px;
        top: 21px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #373a47;
    }

    .bm-cross-button {
        height: 36px;
        width: 30px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /* General sidebar styles */
    .bm-menu {
        background-color: #B54949;
        opacity: 1;
        color: white;
        padding: 2.6em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`;

const Burger = (props) =>{
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState("!");
    const classes = useStyles();
    return (
        <>
            <StyledBurger>
                <Menu class="Menu" right>
                    <Link to='/' className="menu-item">Início</Link>
                    <Link to='/Institucional' className="menu-item">Institucional</Link>
                    <Link to='/Servicos' className="menu-item">Serviços</Link>
                    <Link to='/Notices' className="menu-item">Notícias</Link>
                    <Link to='/Contactos' className="menu-item">Contactos</Link>
                    <div className={classes.search}>
                        <form>
                           <Link 
                            class="link" 
                            to={
                                    {
                                        pathname:'/Pesquisar',
                                        state:{
                                            search: search,
                                            value: true,
                                        }
                                    }
                                }
                            >
                                <button>
                                <IconButton 
                                    className="btnS" 
                                    aria-label="search" 
                                    color="inherit"
                                >
                                    <SearchIcon />
                                </IconButton>
                                </button>
                            </Link>
                        
                    
                        <InputBase
                        placeholder="Pesquisar…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                    </form>
                </div>
                </Menu>
            </StyledBurger>
            
        </>
    )
}

export default Burger;