import React from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';

const Div = styled.div `
    
    width: 100%;
    background-color: #B54949;
    margin: 5% 0%;
    color: white;
    text-align: center; 
    padding: 2%;
    @media (max-width: 980px) {
     
    }
    @media (max-width: 610px) {
        
    }
    @media (max-width: 481px) {
        h5{
            font-size: 0.5em !important;
        }
    }
    .count{
        display: flex;
        justify-content: space-evenly;
        
        h1{
            font-size: 2.2em;
        }
        h5{
            font-size: 0.8em;
        }
    }
`;

const Statistic = () => (
    <>
        <Div>
            <div className="count">
                <div>
                    <h1>
                        <CountUp 
                            end={25}
                            duration={15}
                        />K+
                    </h1>
                    <h5>TÍTULOS</h5>
                </div>
                <div>
                    <h1>
                        <CountUp 
                            end={12}
                            duration={15}
                        />K+
                    </h1>
                    <h5>CÓDICES</h5>
                </div>
                <div>
                    <h1>
                        <CountUp 
                            end={5}
                            duration={15}
                        />K+
                    </h1>
                    <h5>CAIXAS DE DOCS</h5>
                </div>
            </div>
        </Div>
    </>
)

export default Statistic;