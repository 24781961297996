import React from "react";
import styled from 'styled-components';
import RightNav from './RightNav';


const Nav = styled.div`
    width: 100%;
`;


const Header = (props) => (
    <Nav>
        <RightNav/>
    </Nav>
);

export default Header;