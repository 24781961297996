import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Div = styled.div`

width: 100%;
      
.Mui-selected{
    background-color: #B54949 !important;
    color: white;
}
.tab{
  padding: 0px !important;
}
.topTxt{
  font-size: 30px;
  color: white;
}
.textPaper{
  background-color: #B54949 !important;
}
.MuiTabs-indicator{
    background-color: #B54949 !important;
}
.MuiTabPanel-root{
  width: 100%;
  margin: 0;
  padding: 0;
}
.bodyTxt{
  text-align: justify;
}
.p{
  color: white;
}

@media (max-width: 425px) {
  .info{
    width: 90%;
    margin-left: 9px;
  } 
  .painel{
    width: 100%;
    margin-left: 0.2px;
  }
}
@media (max-width: 375px) {
  .info{
    width: 100%;
    margin-left: -10px;
  } 
  .painel{
    width: 100%;
    margin-left: 0.5px;
  }
}
@media (max-width: 320px) {
  .info{
    width: 100%;
    margin-left: -10px;
  } 
  .painel{
    width: 100%;
    margin-left: 0.5px;
  }
}
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    flexGrow: 1,
    width: '100%',
    margin: '0.2%',
    
  },
}));

export default function Ab1(props) {
  let location = useLocation();
  let id = (location.state) ? location.state.id : 0;
  const classes = useStyles();

  const [value, setValue] = React.useState(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Div>
        <div className={classes.root}>
        <Paper square elevation={0}>
            <Tabs 
              value={value} 
              onChange={handleChange} 
              variant="scrollable"
              scrollButtons="auto"  
              aria-label="simple tabs example">
                {
                  props.dados.map( (item, index) => {
                    if(item.orgao == 0)
                    {
                      return (
                        <Tab label={item.titulo} {...a11yProps(index)} />
                      )
                    }
                  })
                }
            </Tabs>
            
        </Paper>
        {
          props.dados.map( (item, index) => {
              if(item.orgao == 0)
                {
                  return (
                    <TabPanel value={value} index={index} className='tab'>
                        <div className='bodyTxt painel' dangerouslySetInnerHTML={{__html: item.descricao}}>
                          
                        </div>
                    </TabPanel>
                  )
                }
          })
        }
        </div>
    </Div>
  );
}
