import React from 'react';
import { Link, useLocation} from 'react-router-dom';
import { 
          Card,
          CardActionArea, 
          CardContent,
          CardActions,
          CardMedia,
          Button,
          Grid,
          Typography
} from '@material-ui/core';
import styled from 'styled-components';

const Div = styled.div `
  
  box-shadow: none !important;
  width: 100%;
  background-color: #FBFBFB;

  .grid{
    width: 100%;
    margin: 1%;
    margin-bottom: 5%;
  }

  .link{
    text-decoration: none;
  }

  .space{
    text-align: center;
  }
`

export default function Space(props) {

  return (
    <Div>
      <div className="space">
        <h1 style={{margin:'25px', color: '#1E266D'}}>O nosso espaço</h1>
      </div>
      <Grid container spacing = {0} justify='space-evenly'>
         {props.dados.map((tile) => (
            <Grid 
              item  
              className='grid'
              justify='flex-start'
              xs={12}
              sm={6}
              md={4}
              lg={3} 
            >
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="150"
                    image= {`https://ana.gov.ao/backend/u/${tile.img}`}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {tile.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {tile.description.substr(0, 100)}...
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    <Link class="link" to={
                                            {
                                              pathname:'/Espaco',
                                              state:{
                                                route: '/destaque/show',
                                                id: tile.id
                                              }
                                            }
                                          }>Ler Mais
                    </Link> 
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Div>
  );
}
