import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import {Grid, Typography} from '@material-ui/core';

const Roda = styled.div`
    
    
    background-color: black;
    width: 100%;
    padding: 5%;
   
    .grid{
        width: '86%';
        color: white;
        margin-left: 1%;

        .gridItem{
            font-size: 10px;
        }
        .link{
            text-decoration: none;
            color: white;
        }
        a{
            text-decoration: none;
            color: white;
        }
        .copy{
            text-align: center;
            width: 86%;
            border-top: 1px solid #333;
            font-size: 13px;
            color: #999 !important;
            padding: 30px 0px 26px;
        }
    }
    @media (max-width: 1024px){
        .grid{
            display: flex;
            justify-content: space-between;
            padding: 0% 0% 0% 13%;
        }
    }
`;

function Footer() {
  return (
    <div>
        <Roda>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                className="grid"
                spacing={2}
            >
                    <Grid 
                        item 
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3} 
                        container 
                        direction="column" 
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                                CONTACTOS
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <p>+244 990 000 000</p>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <p>geral@ana.gov.ao</p>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <p>ANA 2020</p>
                            </Typography>
                        </Grid>  
                    </Grid>
                    <Grid 
                        item 
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3} 
                        container 
                        direction="column" 
                        className="gridItem"
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                                ACESSO RÁPIDO
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Link class="link" to='/Institucional'>Institucional</Link>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Link class="link" to='/Servicos'>Serviços</Link>
                            </Typography>
                            
                            <Typography variant="body2" gutterBottom>
                                <Link class="link" to='/Notices'>Notícias</Link>
                            </Typography>
                        </Grid>  
                    </Grid>
                    
                    <Grid 
                        item 
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3} 
                        container 
                        direction="column" 
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                                LINKS ÚTEIS
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <a href="https://www.sepe.gov.ao/ao/">SEPE - Serviços Públicos Electrónicos</a>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <a href="https://www.governo.gov.ao/">Portal Oficial Do Governo</a>
                            </Typography>
                        </Grid>  
                    </Grid>
                    <span class="copy">Copyright  ANA  2020 <a href="https://infosi.gov.ao/index.html">Desenvolvido pelo INFOSI</a></span>
            </Grid>
            
        </Roda>
    </div>
  );
}

export default Footer;
