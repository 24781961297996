import React, { useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import api from '../../Services/api';

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(250, 250, 250);
    padding: 50px;
`;

const Tudo = styled.div`
    #l3{
        border-bottom: 2px solid #B54949;
    }
    .h1hhhh{
        width: 100%;
        height: 100px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-top: 70px;
    }
    .h11{
        margin-left: 6%;
        padding-top: 25px;
        font-size: 30px;
    }
    
   
    
    .container{
        width: 1280px;
        position: relative;
        display: flex;
        
       
    }
    
    
    
    .container .card .face{
        width: 280px;
        height: 200px;
        transition: 0.5s;
        margin: 0px 20px;
    }
    
    .container .card .face.face1{
        position: relative;
        background: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        transform: translateY(100px);
    }
    
    .container .card:hover .face.face1{
        background: #b54949;
        transform: translateY(0);
    }
    
   
    
    .container .card:hover .face.face1 .content{
        opacity: 1;
    }
    
    .container .card .face.face1 .content img{
        max-width: 100px;
    }
    
    .container .card .face.face1 .content h3{
        margin: 10px 0 0;
        padding: 0;
        color: #fff;
        text-align: center;
        font-size: 1.4em;
    }
    
    .container .card .face.face2{
        position: relative;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
    }
    
    .container .card:hover .face.face2{
        transform: translateY(0);
    }
    
    .container .card .face.face2 .content p{
        margin: 0;
        padding: 0;
    }
    
    .container .card .face.face2 .content a{
        margin: 15px 0 0;
        display:  inline-block;
        text-decoration: none;
        font-weight: 900;
        color: #333;
        padding: 5px;
        border: 1px solid #333;
    }
    
    .card{
        flex:150px 150px 150px 150px;
        margin:0px;
    }

    .container{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }

`;



export default function Servicos(){

    const[data, setData] = React.useState([])

    useEffect(() => { 
        
        api.get('/servicos')
            .then(res => {
                console.log(res.data)         
                setData(res.data)
            })
            .catch(error=>{
                console.log("Error")
            })
        
    }, [])

    window.scrollTo(0, 0);
    return (
        <Tudo>
            <Header />
                <div class="h1hhhh">
                    <p class="h11">Servicos</p>
                </div>  
            <Div>
                <div class="container">
                    {
                        data.map((item, index) => {
                            if(item.orgao == 1){
                                return (
                                    <div class="card" key={index}>
                                        <div class="face face1">
                                            <div class="content">
                                                <img src=""/>
                                                <h3> {item.titulo} </h3>
                                            </div>
                                        </div>
                                        <div class="face face2">
                                            <div class="content">
                                                <p dangerouslySetInnerHTML={{__html: item.descricao.substr(0, 100) + "..."}}></p>
                                                <Link class="App-link" to={
                                                                            {
                                                                            pathname:'/Espaco',
                                                                            state:{
                                                                                route: '/servicos/show',
                                                                                id: item.id
                                                                            }
                                                                        }
                                                                    }>Ler Mais
                                                </Link> 
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </Div>
            <Footer />
        </Tudo>
    )
}