import React from 'react';
import ParentContainer from './parentContainer.js';
import GridImages from './GridImages.js';

class GalleryComponents extends React.Component{
    constructor(props){
        super(props);
    }
    render(){

        return(
         <div>                 
                 <>
                    <ParentContainer dados={this.props.dados} />
                    <GridImages dados={this.props.dados} />
                 </>           
            
        </div>
        )
    }
}

export default GalleryComponents;