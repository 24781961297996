import React from 'react'
import './App.css'
import GalleryBottom from './imageBottom.js';
import styled from 'styled-components';

const Div = styled.div`
    width: 100%;
`

class ParentContainer extends React.Component {

    render() {
        return (
            <Div>
                <div className="parentContainer">
                    <span className="close" onClick={function () { document.getElementsByClassName("parentContainer")[0].style.display = 'none'; }}>&times;</span>
                    <div className="imgDiv">
                        <img className="imgContainer" src="" />
                        <p>Texto de descrição</p>
                    </div>
                    <GalleryBottom dados={this.props.dados} />
                </div>
            </Div >
        )
    }
}

export default ParentContainer;