import React from 'react';
import img_1 from '../../img/1.png';
import styled from 'styled-components';

const Div = styled.div`
.h1h{
    color: #1E266D;
    text-align: center;
}
.img{
    border-radius: 100%;
    height: 100px;
    width: 100px;
    text-align: center;
    margin-left: 120px;
    margin-bottom: 20px;
}
.p{
    width: 65%;
    text-align: center;
    margin-left: 50px;
}
.coluna{
    display: flex;
    margin-top: 65px;
}
h3{
    text-align: center;
}

`;

const PanelService = () => (
    <Div>
        <div class="h1h">
            <h1>Nossos Serviços</h1>
        </div>
        <div class="coluna">
            <div class="coluna1">
                <img class="img" src={img_1} />
                <h3>Departamento de Arquivistica</h3>
                <p class="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sit enim libero ipsum fermentum. Cras massa nam semper
                    ectus etiam a. Nunc enim porta tellus feugiat tincidunt. 
                    Iaculis purus nisi, mollis lectus odio sed vestibulum.
                    Volutpat lobortis sed ac vivamus euismod amet odio.
                </p>
            </div>

            <div class="coluna2">
                <img class="img" src={img_1} />
                <h3>Departamento de Investigação</h3>
                <p class="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sit enim libero ipsum fermentum. Cras massa nam semper
                    ectus etiam a. Nunc enim porta tellus feugiat tincidunt. 
                    Iaculis purus nisi, mollis lectus odio sed vestibulum.
                    Volutpat lobortis sed ac vivamus euismod amet odio.
                </p>
            </div>

            <div class="coluna3">
                <img class="img" src={img_1} />
                <h3>Códices</h3>
                <p class="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sit enim libero ipsum fermentum. Cras massa nam semper
                    ectus etiam a. Nunc enim porta tellus feugiat tincidunt. 
                    Iaculis purus nisi, mollis lectus odio sed vestibulum.
                    Volutpat lobortis sed ac vivamus euismod amet odio.
                </p>
            </div>

            <div class="coluna4">
                <img class="img" src={img_1} />
                <h3>Visual e Arquivo Oral</h3>
                <p class="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Sit enim libero ipsum fermentum. Cras massa nam semper
                    ectus etiam a. Nunc enim porta tellus feugiat tincidunt. 
                    Iaculis purus nisi, mollis lectus odio sed vestibulum.
                    Volutpat lobortis sed ac vivamus euismod amet odio.
                </p>
            </div>
        </div>
    </Div>
);

export default PanelService;