import React from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-slideshow-image/dist/styles.css';

const Div = styled.div`
    width: 100%;
    height: 84vh;    

    .each-slide > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 84vh;  
    }
    span{
        color: white;
        font-size: 220%;
        width: 65%;
        text-align: center;
    }
    .img{
      width: 100%;
      background-size:cover;
      background-position: center center;
      background-repeat: no-repeat;
      filter: brightness(85%);
    }
    .icon{
      color: white;
    }
    .icon:hover{
      color: #ccc;
      cursor: pointer;
    }
    @media (max-width: 768px){
      .each-slide span{
        font-size: 120%;
      }
    }
`


const properties = {
  indicators: true,
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  prevArrow: <ArrowBackIosIcon className="icon" style={{width: "30px", marginRight: "-30px", background: 'none'}}/>,
  nextArrow: <ArrowForwardIosIcon className="icon" style={{width: "30px", marginLeft: "-30px", background: 'none'}}/>
};

const SlideShow = (props) => {
    return (
    <Div>
      <div>
        <Slide {...properties}>
        {props.dados.map( item => {
            return (
              <div className="each-slide">
                <div className="img" style={{'backgroundImage': `url(https://ana.gov.ao/backend/u/${item.img})`}}>
                  <span> {item.descricao} </span>
                </div>
              </div>
            )
          })}
        </Slide>
      </div>
    </Div>
    )
};

export default SlideShow;