import React from 'react';

class ImageBottom extends React.Component {


    constructor(props) {
        super(props);

    }
    render() {
        return (

            <div className="bottomGallery">
                {this.props.dados.map((tile) =>
                    tile.img && (<img src={`https://ana.gov.ao/backend/u/${tile.img}`} className="imgBottom" onClick={function () {
                        document.getElementsByClassName("imgContainer")[0].style.transition = "1s";
                        document.getElementsByClassName("imgContainer")[0].src = `https://ana.gov.ao/backend/u/${tile.img}`;
                    }} />)
                )}
            </div>
        )
    }
}


export default ImageBottom;