import React from 'react';
import Header from '../../components/Header';
import Form from '../../components/Form';
import Location from '../../components/Location';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import Mapa from '../../components/Mapa';

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(250, 250, 250);
    padding: 50px;
`;

const Tudo = styled.div`
    #l7{
        border-bottom: 2px solid #B54949;
    }
    .h1hhhh{
        width: 100%;
        height: 100px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-top: 70px;
    }
    .h11{
        margin-left: 6%;
        padding-top: 25px;
        font-size: 30px;
    }
    
    .list-group-item {
        position: relative;
        display: block;
        padding: .95rem .95rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 2px solid rgba(0,0,0,.125);
    }

    .list-group-item:first-child {
        border-top-left-radius: .35rem;
        border-top-right-radius: .35rem;
    }
`;



export default function Catalogo(){
    window.scrollTo(0, 0);
    return (
        <Tudo>
            <Header />
                <div class="h1hhhh">
                    <p class="h11">Catálogo</p>
                </div>
               
                
                
            <Div>
            <div class="section-empty section-item">
            <div class="container content">
            <div class="row">
            <div class="col-md-12">
            <div class="list-group accordion-list" data-time="1000" data-type='accordion'>
            <div class="list-group-item">
                                    <svg width="3em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
                                        <path fill-rule="evenodd" d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
                                        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
                                    </svg>
                                    <a href=".pdf" target="_blank"><b>Catalogo de Imagem  </b></a>
                                    <div class="panel">
                                       
                                    </div>
                                </div>
                               
                                <div class="list-group-item">
                                    <svg width="3em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
                                        <path fill-rule="evenodd" d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
                                        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
                                      </svg>
                                    <a href=".pdf" target="_blank"><b>Catalogo de Imagem sobre a exposição do Rei Mandume</b></a>
                                    <div class="panel">
                                       
                                    </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                
            </Div>
           
            <Footer />
        </Tudo>
    )
}