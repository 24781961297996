import React from 'react';
import Header from '../../components/Header';
import List from '../../components/listNotices';
import Footer from '../../components/Footer';
import styled from 'styled-components';

const Div = styled.div`
    margin-top: 70px;
`;

const Tudo = styled.div`
    #l4{
        border-bottom: 2px solid #B54949;
    }
`;

export default function Notices() {
    window.scrollTo(0, 0);
    return (
        <Tudo>
            <Header />
            <Div>
                <List />
                <Footer />
            </Div>
        </Tudo>
    );
}

