import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import api from '../../Services/api';

const Forms = styled.form`
width: 100%;
border-top: 1px solid #B54949;
margin-top: 10px;
background-color: #F5F5F5;

.form{
  margin: 50px 0px 0px 150px;
  height: 100px;
}

div{
  margin: 20px;
  display: 'flex';
  direction: column;
}
.text{
  width: 310px;
  height: 35px;
  border-bottom: 1px solid #B54949;
  background-color: #F5F5F5;
}
.btn{
  width: 255px;
  height: 35px;
  padding: 9px 17px;
  background-color: #B54949;
  border-radius: 100px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-left: 30px;
}
.area{
  width: 310px;
  margin-top: 20px;
  background-color: #F5F5F5;
  border-bottom: 1px solid #B54949;
}

@media (max-width: 1024px){
  .form{
    width: 375px;
    margin-left: 14%;
  }
  .btn{
    margin-left: 30px;
  }
}
@media (max-width: 768px){
  font-size: 15px;
  height: 87vh;
  width: 80%;
  .form{
    width: 265px;
    margin-left: 5%;
  }
  .btn{
    width: 150px;
    margin-left: 50px;
  }
  .area{
    width: 265px;
    margin-top: 20px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #B54949;
  }
}
@media (max-width:425px){
  width: 180%;
  .form{
    width: 50%;
    margin-left: 1%;
  }
  .btn{
    margin-left: 80px;
  }
  .area{
    width: 310px;
  }
}
`;

const Form = ()=>{
  const { register, handleSubmit} = useForm();

  function onSubmit(data) {
    const dados = new FormData();

    dados.append("nome", data.nome + " " + data.sobrenome);
    dados.append("email", data.email);
    dados.append("mensagem", data.sms);
    
    api.post('/contacto', dados)
      .then(res => {
        console.log(res);
      })
  }

  return (
    <>
    <div  style={{margin: '-20px',position:'absolute',  right:'1000px' }}><b>Fale Connosco</b></div>
    <Forms onSubmit={handleSubmit(onSubmit)}>
      <div className='form'>
        <div>
          <p><label for="nome">Nome:</label></p>
          <input type="text" id="nome" name="nome" className='text' ref={register({
            required: "Digite o seu nome",
          })}/>
        </div>
        <div>
          <p><label for="sobrenome">Sobrenome:</label></p>
          <input type="text" id="sobrenome" name="sobrenome" className='text' ref={register({
            required: "Digite o seu Sobrenome",
          })}/>
        </div>
        <div>
          <p><label for="email">Email:</label></p>
          <input type="email" id="email" name="email" className='text' ref={register({
            required: "Digite o seu email",
          })}/>
        </div>
        <div>
          <p><label for="sms">Mensagem:</label></p>
          <textarea name="sms" rows="10" cols="30" className='area' ref={register()}>
          </textarea>
        </div>
        <div>
          <input type="submit" value='Enviar' name="btn" className='btn' />
        </div>
      </div>
    </Forms>
    </>
  );
}

export default Form;
