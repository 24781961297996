import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Panel from '../Panel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Ab1 from '../../components/Ab1';
import api from '../../Services/api';

const Div = styled.div`
    width: 100%;
    .Mui-selected{
        background-color: #B54949 !important;
        color: white;
    }
    .tab{
      padding: 0px !important;
    }
    .topTxt{
      font-size: 30px;
      color: white;
    }
    .legis{
      padding: 0px 30px;
    }
    .leg{
      text-decoration: none;
      cursor: pointer;
      color: black;
    }
    .leg:hover{
      color: #B54949;
    }
    li{
      padding: 8px;
    }
    .textPaper{
      background-color: #B54949 !important;
    }
    .MuiTabs-indicator{
        background-color: #B54949 !important;
    }
    .MuiTabPanel-root{
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .bodyTxt{
      text-align: justify;
    }
    .p{
      color: white;
    }
    
    @media (max-width: 425px) {
      .info{
        width: 90%;
        margin-left: 9px;
      } 
      .painel{
        width: 100%;
        margin-left: 0.2px;
      }
    }
    @media (max-width: 375px) {
      .info{
        width: 100%;
        margin-left: -10px;
      } 
      .painel{
        width: 100%;
        margin-left: 0.5px;
      }
    }
    @media (max-width: 320px) {
      .info{
        width: 100%;
        margin-left: -10px;
      } 
      .painel{
        width: 100%;
        margin-left: 0.5px;
      }
    }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    flexGrow: 1,
   
    
  },
}));

export default function Ab(props) {

  const[data, setData] = React.useState([])

  React.useEffect(() => { 
    
    api.get('/servicos')
        .then(res => {            
          setData(res.data)
          console.log(res.data)
         })
         .catch(error=>{
             console.log("Error")
         })
    
  }, [])

  let location = useLocation();
  let id = (location.state) ? location.state.id : 0;
  let exp = (location.state) ? location.state.exp : 1;

  const classes = useStyles();
  const [value, setValue] = React.useState(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return(
    <Div>
        <div className={classes.root}>
        <Paper square elevation={0}>
            <Tabs 
              value={value} 
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto" 
              aria-label="simple tabs example">
            <Tab label="História" {...a11yProps(0)} />
            <Tab label="Organograma" {...a11yProps(1)} />
            <Tab label="Missão e Objectivos" {...a11yProps(2)} />
            <Tab label="Legislação" {...a11yProps(3)} />
            <Tab label="Orgãos" {...a11yProps(4)} />
            </Tabs>
            
        </Paper>
        <TabPanel value={value} index={0} className='tab'>
            <div className='bodyTxt painel' >
            {props.dados.Historia && (
              <Panel dados={props.dados.Historia} exp = {exp} />
            )}
            </div>
        </TabPanel>
        <TabPanel value={value} index={1} className='tab'>
              {props.dados.Organograma && (
                <img src={`https://ana.gov.ao/backend/u/${props.dados.Organograma.img}`} style={{marginLeft: '7%', width: '80%', height: '20%'}}/>
              )}
        </TabPanel>
        <TabPanel value={value} index={2} className='tab'>
            <div className='bodyTxt'>
            <Typography variant="body1" gutterBottom>
            <div class="info">
              <p class="p">.</p>
                {props.dados.MissaoObjectivos? props.dados.MissaoObjectivos.descricao: ''}
              <p class="p">.</p>
              <p class="p">.</p>
              </div>
              </Typography>
              
            </div>
        </TabPanel>
        <TabPanel value={value} index={3} className='tab'>
            <div className='legis'>  
              <ul>
                {props.dados.Legislacao &&
                  props.dados.Legislacao.map((item) => <li><a class="leg" target="_blank" href={`https://www.ana.gov.ao/backend/u/${item.pdf}`}>{item.titulo}</a></li>)
                }
              </ul> 
            </div>
        </TabPanel>
        <TabPanel value={value} index={4} className='tab'>
            <Ab1 dados={data} />
        </TabPanel>
        
        </div>
    </Div>
  );
}
