import React from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import api from '../../Services/api';


const Div = styled.div`
    margin-top: 75px;

    .h1hhhh{
        width: 100%;
        height: 75px;
        color: rgba(0, 0, 0, 0.46);
        background-color: #c4c4c4;
        margin-bottom: 40px;
    }
    .h11{
        margin-left: 10%;
        padding-top: 20px;
        font-size: 25px;
    }
    
    .input{
        border: 1px solid #ccc;
        margin: 10px;
        padding: 5px;
        width: 30%;
        margin-left: -0.2%;
    }
    .input:hover{
        border: 1px solid #1E266D;
        color: #1E266D;
    }
    .num{
        width: 90%;
        margin-left: 5%;
    }
    .submit{
        border: 1px solid #ccc;
        padding: 7px;
        background: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: -5px;
    }
    p{
        text-align: justify;
    }
    .resultados{
        border-bottom: 1px solid #ccc;
        padding: 30px 0px;
    }
    h5{
        padding: 0px 0px 15px 0px;
    }
    .titulo{
        text-decoration: none;
        color: black;
    }
    .titulo:hover{
        color: #1E266D;
        text-decoration: underline;
    }
    .body{
        width: 90%;
        margin-left: 5%;

        .line{
            display:flex;
            justify-content:space-between;
            border-bottom: 2px solid #1E266D;
            margin: 30px 0px 15px 0px;
            width: 100%;
        }
    }
    
`;

const search =[
    ""
]
export default class Pesquisar extends React.Component{

    state = {
        pesquisa: [],
    }

    componentDidMount(){
        this.searchLoad();
    }


    searchLoad = async () =>{
        const res = await api.get(`/pesquisar/${this.props.location.state.search}`);

        this.setState({pesquisa: res.data})
    }

    
    render(){
    window.scrollTo(0, 0);

    if(this.props.location.state.value) {
        this.searchLoad();
        this.props.location.state.value = !this.props.location.state.value;
    }

    return (
        <>
            <Header/>
            <Div>
                <div class="h1hhhh">
                    <p class="h11">Pesquisas</p>
                </div>
                <div className="num">
                    {this.state.pesquisa && this.state.pesquisa.map( x => {
                        if(x.total > 0)
                            return <p>Foram encontrados <b>{x.total }</b> resultados para o termo <b>"{this.props.location.state.search}"</b>.</p>
                        if(x.total == 0)
                            return <p>Não foi encontrado nenhum resultado para o termo <b>"{this.props.location.state.search}"</b>.</p>
                    })}
                    
                    <input type="password" class="input" placeholder={this.props.location.state.search} />
                    <input type="submit" class="submit" value="Pesquisar"/>
                </div>
                {this.state.pesquisa !== "" && 
                    this.state.pesquisa.map(item => {
                        return (
                            <div className="body">
                                <div>
                                   {(item.subtotal >= 1) &&
                                        (<div class="line">
                                            <h3> {item.Categorias}</h3>
                                            <small style={{"color": "rgba(0,0,0,.7)"}}>{item.subtotal} Resultados </small>
                                        </div>)
                                    }
                                    {(item.Items) && 
                                        item.Items.map(subItem => {
                                            let valor = (subItem.descricao) ? subItem.descricao.split(".")[0] : "";
                                            let link = "/Pesquisar";
                                            let route, id, exp;
                                            
                                            
                                            switch(item.Categorias){
                                                case "Noticias":
                                                    link = '/Espaco';
                                                    route = '/noticia/show';
                                                    id = subItem.id;
                                                break;
                                                case "Servicos":
                                                    link = '/Servicos';
                                                    id = 0;
                                                    switch(subItem.titulo){
                                                        case "Departamento de Arquivistica":
                                                            id = 0;
                                                        break;
                                                        case "Departamento de Investigação e Atendimento":
                                                            id = 1;
                                                        break;
                                                        case "Códices":
                                                            id = 2;
                                                        break;
                                                        case "Visual e Arquivo Oral":
                                                            id = 3;
                                                        break;

                                                        default:
                                                        break;
                                                    }
                                                break;
                                                case "Destaques":
                                                    link = '/Espaco';
                                                    route = '/destaque/show';
                                                    id = subItem.id;
                                                break;
                                                case "Historial":
                                                    link = '/Institucional';
                                                    id = 0;
                                                    switch(subItem.titulo){
                                                        case "Historial":
                                                            exp = 1;
                                                        break;
                                                        case "Informação existente no ANA":
                                                            exp = 2;
                                                        break;
                                                        case "Período colonial":
                                                            exp = 3;
                                                        break;
                                                        case "Período pós independência":
                                                            exp = 4;
                                                        break;

                                                        default:
                                                        break;
                                                    }
                                                break;
                                                case "Missão e Objectivos":
                                                    link = '/Institucional';
                                                    id = 2;
                                                break;
                                                case "Legislação":
                                                    link = '/Institucional';
                                                    id = 3;
                                                break;
                                                default:
                                                break;
                                            }
                                            
                                            return (
                                                <div class="resultados">
                                                    <Link class="titulo" to={
                                                                                {
                                                                                pathname: link,
                                                                                    state:{
                                                                                        route: route,
                                                                                        id: id,
                                                                                        exp: exp,
                                                                                    }
                                                                                }
                                                                            } 
                                                    ><h5 dangerouslySetInnerHTML={{__html: subItem.titulo}}></h5></Link>
                                                    {(valor) &&  <p dangerouslySetInnerHTML={{__html: valor + "..."}}></p>}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </Div>
            <Footer />
        </>
    )
}
}